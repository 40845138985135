<template>
  <div class="p-2">
    <app-error-message
      v-if="
        (!Object.keys(doc).length || !self.shared_list.length) && !self.loading
      "
      :message="
        !Object.keys(doc).length
          ? `Select a project, folder or file to view it's share information.`
          : `You haven't shared this item.`
      "
      page_icon="fa fa-share-from-square"
      hide_page_type
      :hide_button="
        !Object.keys(doc).length
          ? true
          : appContext.config.globalProperties.$appPermissions('share').edit !=
              1 ||
            appContext.config.globalProperties.$appPermissions('share')
              .manage != 1
      "
      button_icon="fa fa-share-from-square"
      button_text="Share"
      @add-new="$emit('view-share-modal')"
    />
    <div v-if="self.loading" class="mt-3 text-center">
      <progress-spinner
        style="width: 30px; height: 30px"
        strokeWidth="5"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
    <Listbox
      v-if="self.shared_list.length && !self.loading"
      :options="self.shared_list"
      optionLabel="name"
      class="w-full"
      listStyle="max-height:250px"
    >
      <template #option="{ option }">
        <div
          @click="
            appContext.config.globalProperties.$appPermissions('share').edit ==
              1 ||
            appContext.config.globalProperties.$appPermissions('share')
              .manage == 1
              ? $emit('view-share-modal')
              : null
          "
          class="col-12 p-0"
        >
          <div class="flex flex-column xl:flex-row xl:align-items-start">
            <Avatar
              v-if="option.image"
              :image="
                appContext.config.globalProperties.$getFullPath(option.image)
              "
              class="mr-2"
              shape="circle"
            />
            <Avatar
              v-else-if="option.name"
              :label="option.name.charAt(0)"
              class="mr-2"
              shape="circle"
            />
            <Avatar
              v-else
              :label="option.email.charAt(0)"
              class="mr-2"
              shape="circle"
            />
            <div
              class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4"
            >
              {{ option.name }} ({{ option.email }})
            </div>
          </div>
        </div>
      </template>
    </Listbox>
    <template
      v-if="
        Object.keys(doc).length &&
        (appContext.config.globalProperties.$appPermissions('share').edit ==
          1 ||
          appContext.config.globalProperties.$appPermissions('share').manage ==
            1)
      "
    >
      <Button
        v-if="self.shared_list.length && !self.loading"
        @click="$emit('view-share-modal')"
        class="mt-2 button-primary"
        icon="fa fa-share-from-square"
        label="Share"
      />
    </template>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  watch,
} from "vue";
export default defineComponent({
  name: "ActivitiesShare",
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["view-share-modal"],
  setup(props: any) {
    const { appContext } = getCurrentInstance() as any;
    const self = reactive({
      shared_list: [],
      loading: true,
    });
    watch(
      () => props.doc,
      () => {
        getDocumentSharedList();
      }
    );
    onMounted(() => {
      getDocumentSharedList();
    });
    function getDocumentSharedList() {
      if (Object.keys(props.doc).length) {
        if (
          props.doc.permission.edit == 1 ||
          props.doc.permission.manage == 1
        ) {
          self.loading = true;
          appContext.config.globalProperties.app_service.getData(
            `${props.doc.type == "project" ? "projects" : "media"}/${
              props.doc.id
            }/share`,
            null,
            (response: any, error: any) => {
              if (response) {
                self.shared_list = response.data ? response.data : [];
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
              self.loading = false;
            },
            false
          );
        } else {
          self.loading = false;
        }
      } else {
        self.loading = false;
      }
    }
    return {
      self,
      appContext,
      getDocumentSharedList,
    };
  },
});
</script>