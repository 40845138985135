import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = {
  key: 1,
  class: "mt-3 text-center"
}
const _hoisted_3 = { class: "flex flex-column xl:flex-row xl:align-items-start" }
const _hoisted_4 = { class: "flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error_message = _resolveComponent("app-error-message")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        (!Object.keys(_ctx.doc).length || !_ctx.self.shared_list.length) && !_ctx.self.loading
      )
      ? (_openBlock(), _createBlock(_component_app_error_message, {
          key: 0,
          message: 
        !Object.keys(_ctx.doc).length
          ? `Select a project, folder or file to view it's share information.`
          : `You haven't shared this item.`
      ,
          page_icon: "fa fa-share-from-square",
          hide_page_type: "",
          hide_button: 
        !Object.keys(_ctx.doc).length
          ? true
          : _ctx.appContext.config.globalProperties.$appPermissions('share').edit !=
              1 ||
            _ctx.appContext.config.globalProperties.$appPermissions('share')
              .manage != 1
      ,
          button_icon: "fa fa-share-from-square",
          button_text: "Share",
          onAddNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view-share-modal')))
        }, null, 8, ["message", "hide_button"]))
      : _createCommentVNode("", true),
    (_ctx.self.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_progress_spinner, {
            style: {"width":"30px","height":"30px"},
            strokeWidth: "5",
            animationDuration: ".5s",
            "aria-label": "Loading"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.self.shared_list.length && !_ctx.self.loading)
      ? (_openBlock(), _createBlock(_component_Listbox, {
          key: 2,
          options: _ctx.self.shared_list,
          optionLabel: "name",
          class: "w-full",
          listStyle: "max-height:250px"
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.appContext.config.globalProperties.$appPermissions('share').edit ==
              1 ||
            _ctx.appContext.config.globalProperties.$appPermissions('share')
              .manage == 1
              ? _ctx.$emit('view-share-modal')
              : null
          )),
              class: "col-12 p-0"
            }, [
              _createElementVNode("div", _hoisted_3, [
                (option.image)
                  ? (_openBlock(), _createBlock(_component_Avatar, {
                      key: 0,
                      image: 
                _ctx.appContext.config.globalProperties.$getFullPath(option.image)
              ,
                      class: "mr-2",
                      shape: "circle"
                    }, null, 8, ["image"]))
                  : (option.name)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 1,
                        label: option.name.charAt(0),
                        class: "mr-2",
                        shape: "circle"
                      }, null, 8, ["label"]))
                    : (_openBlock(), _createBlock(_component_Avatar, {
                        key: 2,
                        label: option.email.charAt(0),
                        class: "mr-2",
                        shape: "circle"
                      }, null, 8, ["label"])),
                _createElementVNode("div", _hoisted_4, _toDisplayString(option.name) + " (" + _toDisplayString(option.email) + ") ", 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["options"]))
      : _createCommentVNode("", true),
    (
        Object.keys(_ctx.doc).length &&
        (_ctx.appContext.config.globalProperties.$appPermissions('share').edit ==
          1 ||
          _ctx.appContext.config.globalProperties.$appPermissions('share').manage ==
            1)
      )
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.self.shared_list.length && !_ctx.self.loading)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('view-share-modal'))),
                class: "mt-2 button-primary",
                icon: "fa fa-share-from-square",
                label: "Share"
              }))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}